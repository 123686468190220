import { createRouter, createWebHistory } from "vue-router";
const routerHistory = createWebHistory();
// 定义一些路由,每个路由都需要映射到一个组件。
 

const routes = [ 
{
      path: "/MicroservicesPageList",
      name:"MicroservicesPageList",
      component: () => import('@/view/kycms/MicroservicesPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/BackstagecolumnPageList",
      name:"BackstagecolumnPageList",
      component: () => import('@/view/kycms/BackstagecolumnPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/RolesPageList",
      name:"RolesPageList",
      component: () => import('@/view/kyauthority/RolesPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/Tenant_AccountPageList",
      name:"Tenant_AccountPageList",
      component: () => import('@/view/kyauthority/Tenant_AccountPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
  {
        path: "/BranchesPageList",
        name:"BranchesPageList",
        component: () => import('@/view/kyauthority/BranchesPageList.vue'),
        meta:{
       	 keepAlive: false,
        }
    },
// {
//       path: "/Independent_ColumnPageList",
//       name:"Independent_ColumnPageList",
//       component: () => import('@/view/kyauthority/Independent_ColumnPageList.vue'),
//       meta:{
//      	 keepAlive: false,
//       }
//   },
// {
//       path: "/Aes_VersionPageList",
//       name:"Aes_VersionPageList",
//       component: () => import('@/view/kyauthority/Aes_VersionPageList.vue'),
//       meta:{
//      	 keepAlive: false,
//       }
//   },
// {
//       path: "/Tenant_ColumnPageList",
//       name:"Tenant_ColumnPageList",
//       component: () => import('@/view/kyauthority/Tenant_ColumnPageList.vue'),
//       meta:{
//      	 keepAlive: false,
//       }
//   },
// {
//       path: "/Roles_ColumnPageList",
//       name:"Roles_ColumnPageList",
//       component: () => import('@/view/kyauthority/Roles_ColumnPageList.vue'),
//       meta:{
//      	 keepAlive: false,
//       }
//   },
// {
//       path: "/AllocationPageList",
//       name:"AllocationPageList",
//       component: () => import('@/view/kyauthority/AllocationPageList.vue'),
//       meta:{
//      	 keepAlive: false,
//       }
//   }, 

//Products
// {
//       path: "/Products_SkuPageList",
//       name:"Products_SkuPageList",
//       component: () => import('@/view/kyproduct/Products_SkuPageList.vue'),
//       meta:{
//      	 keepAlive: false,
//       }
//   },
{
      path: "/FilePageList",
      name:"FilePageList",
      component: () => import('@/view/kyfile/FilePageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/Products_PurchasePageList",
      name:"Products_PurchasePageList",
      component: () => import('@/view/kyproduct/Products_PurchasePageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/Products_WarehousingPageList",
      name:"Products_WarehousingPageList",
      component: () => import('@/view/kyproduct/Products_WarehousingPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/Products_WarehousePageList",
      name:"Products_WarehousePageList",
      component: () => import('@/view/kyproduct/Products_WarehousePageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/Products_ClassPageList",
      name:"Products_ClassPageList",
      component: () => import('@/view/kyproduct/Products_ClassPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/ProductsPageList",
      name:"ProductsPageList",
      component: () => import('@/view/kyproduct/ProductsPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/Products_OutboundPageList",
      name:"Products_OutboundPageList",
      component: () => import('@/view/kyproduct/Products_OutboundPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/Products_SupplierPageList",
      name:"Products_SupplierPageList",
      component: () => import('@/view/kyproduct/Products_SupplierPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  }, 
{ 
  path: "/login",
  name: "login", 
  component: () => import('@/view/login.vue') 
 },
{ 
  path: "/index",
  name: "index", 
  component: () => import('@/view/home/index.vue') 
 }, 
// {
//     path: "/HelloWorld",
//     name: "HelloWorld",
//     component: () => import('@/components/HelloWorld.vue')
//   },
  {
     path: "/setupIndex",
     name: "setupIndex",
     component: () => import('@/view/setup/setupIndex.vue')
  },
  
  // {
  //      path: "/Roles",
  //      name:Roles,
  //      component: () => import('@/view/kyauthority/Roles.vue')
  //   },
  // {
  //      path: "/Tenant_Account",
  //      name:Tenant_Account,
  //      component: () => import('@/view/kyauthority/Tenant_Account.vue')
  //   },
  // {
  //      path: "/Independent_Column",
  //      name:Independent_Column,
  //      component: () => import('@/view/kyauthority/Independent_Column.vue')
  //   },
  // {
  //      path: "/Aes_Version",
  //      name:Aes_Version,
  //      component: () => import('@/view/kyauthority/Aes_Version.vue')
  //   },
  // {
  //      path: "/Tenant_Column",
  //      name:Tenant_Column,
  //      component: () => import('@/view/kyauthority/Tenant_Column.vue')
  //   },
  // {
  //      path: "/Roles_Column",
  //      name:Roles_Column,
  //      component: () => import('@/view/kyauthority/Roles_Column.vue')
  //   },
  // {
  //      path: "/Allocation",
  //      name:Allocation,
  //      component: () => import('@/view/kyauthority/Allocation.vue')
  //   },

// {
//       path: "/DynamicPageList",
//       name:"DynamicPageList",
//       component: () => import('@/view/kyofficialwebsite/DynamicPageList.vue'),
//       meta:{
//      	 keepAlive: false,
//       }
//   },
  {
        path: "/ClassificationList",
        name:"ClassificationList",
        component: () => import('@/view/kyofficialwebsite/ClassificationList.vue'),
        meta:{
       	 keepAlive: false,
        }
    },
	{
	      path: "/SinglepageAddEdit",
	      name:"SinglepageAddEdit",
	      component: () => import('@/view/kyofficialwebsite/SinglepageAddEdit.vue'),
	      meta:{
	     	 keepAlive: false,
	      }
	  },
	{
		  path: "/DynamicPageList",
		  name:"DynamicPageList",
		  component: () => import('@/view/kyofficialwebsite/DynamicPageList.vue'),
		  meta:{
			 keepAlive: false,
		  }
	  },
     {
		  path: "/DynamicDetail",
		  name:"DynamicDetail",
		  component: () => import('@/view/kyofficialwebsite/DynamicDetail.vue'),
		  meta:{
			 keepAlive: false,
		  }
	  },


{
      path: "/CustomersPageList",
      name:"CustomersPageList",
      component: () => import('@/view/kybill/CustomersPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
  {
	  path: "/Bill_PoolPageList",
	  name:"Bill_PoolPageList",
	  component: () => import('@/view/kybill/Bill_PoolPageList.vue'),
	  meta:{
		 keepAlive: false,
	  }
  },
// {
//       path: "/Bill_Product_DetailsPageList",
//       name:"Bill_Product_DetailsPageList",
//       component: () => import('@/view/kybill/Bill_Product_DetailsPageList.vue'),
//       meta:{
//      	 keepAlive: false,
//       }
//   },
{
      path: "/ActivitPageList",
      name:"ActivitPageList",
      component: () => import('@/view/kybill/ActivitPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/BillPageList",
      name:"BillPageList",
      component: () => import('@/view/kybill/BillPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },
{
      path: "/Cashier_PointPageList",
      name:"Cashier_PointPageList",
      component: () => import('@/view/kybill/Cashier_PointPageList.vue'),
      meta:{
     	 keepAlive: false,
      }
  },


 ];

const router = createRouter({
  history: routerHistory,
  //history: "history",
  //base: "/cmsview",
  routes,
});
//导出路由
export default router;
