import { createApp } from "vue";
import App from "./App.vue"; 
const app = createApp(App);

 //路由
 import router from "@/router/index.js";
 app.use(router);
 
//element-plus PC端UI组建
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
app.use(ElementPlus);
import 'element-plus/dist/index.css'
  
//element-plus/icons-vue
import * as Icons from '@element-plus/icons-vue'
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key])
})

 
	
app.mount("#app");
